import { Box, Grid, Image, Link, Skeleton, Text } from "@chakra-ui/react";
import { useGetEventsQuery } from "../../api/event-slice";
import { paths } from "../../constants/constants";
import { useState } from "react";
export const GetEventsSection = () => {
  const [eventIndex, setEventIndex] = useState(0);
  const { data: eventsData, isLoading: isEventsLoading } = useGetEventsQuery(
    {}
  );
  return isEventsLoading ? (
    <Skeleton></Skeleton>
  ) : (
    <Grid 
      templateColumns={["1fr", "1fr", "1fr", "65% 1fr"]}
    >
      <Box
        height="95%"
        margin={"16px"}
        rounded="2xl"
        overflow="hidden"
        boxShadow={"dark-lg"}
      >
        {eventsData?.events[eventIndex].link ? (
          <Link href={eventsData?.events[eventIndex].link} isExternal={true}>
            <Image
              src={`${paths.API_FILE}${eventsData?.events[eventIndex].posterImage}`}
              height="100%"
            ></Image>
          </Link>
        ) : (
          <Text>No image found</Text>
        )}
      </Box>
      <Box
        // TODO: Make events box scrollable
        height="95%"
        overflow="auto"
        borderRadius="lg"
        borderWidth="5px"
        borderColor="black"
        margin={"16px"}
      >
        {eventsData?.events.map((e, index) => (
          <Box fontWeight={eventIndex === index ? "bold" : ""}>
            <Link onClick={() => setEventIndex(index)}>
              <Text fontSize={["xl", "xl", "xl", "4xl"]}>{e.displayName}</Text>
            </Link>
            <Text fontSize={["lg", "lg", "lg", "2xl"]}>
              {new Date(e.date).toLocaleDateString()}
            </Text>
            <hr
              style={{
                color: "#FF0000",
                height: "3px",
                backgroundColor: "#CCC",
              }}
            />
          </Box>
        ))}
      </Box>
    </Grid>
  );
};
