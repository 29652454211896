import { CarouselImages } from "../models/carousel-model";
import { apiSlice } from './api-slice';

export const carouselSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarouselImages: builder.query({
      query: () => '/CarouselImages',
      transformResponse: (responseData: CarouselImages) => responseData,
      providesTags: ['CarouselImages']
    }),
  }),
});

export const { useGetCarouselImagesQuery } = carouselSlice;