import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useGetMembersQuery } from '../../api/member-slice';
import { OrgnizationTeams } from '../../features/council/organization-teams';
import groupImage from './assets/randomPeople.jpg';
import { paths } from "../../constants/constants";

export const Council = () => {
  const { data: allMembers, isLoading: loading } = useGetMembersQuery({});

  const president = allMembers?.find(
    (member) => member.teamName === 'President'
  );

  return (
    <Skeleton isLoaded={!loading} height='100%'>
      <Box>
        <Stack>
          <Heading mt={5} as='h3' size='lg' textAlign='center'>
            Organization
          </Heading>
          <Box m='auto' width='70%'>
            <Center>
              <OrgnizationTeams members={allMembers} />
            </Center>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Image
          bg={'papayawhip'}
          bgImage={groupImage}
          bgRepeat='no-repeat'
          bgSize='cover'
        ></Image>
      </Box>
      <Box>
        <Stack
          width='80%'
          height='100%'
          m={'auto'}
          mt={20}
          direction={{
            base: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
            xl: 'row',
          }}
        >
          <Box 
            boxSize={'md'} 
            width={'250px'} 
            minWidth={'250px'}
            display={{ base: 'none', md: 'block' }} 
          >
            <Image
              src={`${paths.API_FILE}${president?.image}`}
              objectFit='cover'
              borderRadius={'13em/3em'}
              height={'500px'}
              // ml={{ base: '50%', sm: '50%', md: '0', lg: '0', xl: '0' }}
              boxShadow="0px 4px 4px 0px rgba(0,0,0,0.25)"
            />
          </Box>
          <Box 
            ml={{ base: 0, md: 20 }}
            mt={{ base: 0, md: 20 }}
          >
            <Heading>Our Mission</Heading>
            <Text>
              The Regina Engineering Students Society (RESS) is dedicated to
              creating an inclusive and collaborative environment that empowers
              engineering students at the University of Regina. We foster
              academic excellence, professional growth, and personal development
              through transparent communication, innovative experiences, and
              ethical practices. With a commitment to diversity, integrity, and
              community engagement, RESS aims to cultivate socially responsible
              and skilled engineers who contribute positively to society,
              forging a future marked by excellence, equity, and positive
              impact.
            </Text>
          </Box>
        </Stack>
      </Box>
    </Skeleton>
  );
};
